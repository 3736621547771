window.MathJax = {
    tex: {
      inlineMath: [['$', '$'], ['\\(', '\\)']],
      displayMath: [['$$', '$$'], ['\\[', '\\]']],
      processEscapes: true,
      processEnvironments: true,
      packages: {'[+]': ['base', 'ams', 'newcommand', 'configmacros']}
    },
    svg: {
      fontCache: 'global'
    }
  };
  